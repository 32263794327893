import type { OnDataOptions } from '@apollo/client';
import { useCallback } from '@zavy360/hooks/react';
import { useUserUpdatedSubscription } from '@zavy360/graphql/hooks';
import type { UpcTinyFragment, UserUpdatedSubscription } from '@zavy360/graphql/operations';
import config from '@zavy360/config';
import { log } from '@zavy360/utils/debug';

export default function useUPCSubscription(
  variables: { guid?: string },
  updateFn?: (upc: Pick<UpcTinyFragment, 'id' | 'guid'>) => void
) {
  const onData = useCallback(
    ({ data: subscriptionData }: OnDataOptions<UserUpdatedSubscription>) => {
      if (config.apollo.hooks.subscriptions.debug) {
        log('[Apollo::Hooks::Crud::Subscription::UPC]: ', {
          userUpdated: subscriptionData?.data?.userUpdated?.userPracticeConnection
        });
      }
      if (subscriptionData?.data?.userUpdated?.userPracticeConnection?.guid) {
        const { userPracticeConnection } = subscriptionData.data.userUpdated;
        updateFn?.(userPracticeConnection);
      }
    },
    [updateFn]
  );
  useUserUpdatedSubscription({
    onData,
    shouldResubscribe: true,
    variables: { guid: variables.guid as string },
    skip: !variables?.guid
  });
}
