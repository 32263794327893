/* eslint-disable no-console */
import config from '@zavy360/config';
import type { FieldError } from '@zavy360/graphql/schema';
import { useEffect } from '@zavy360/hooks/react';
import { log as debug } from '@zavy360/utils/debug';
import fastDeepEqual from 'fast-deep-equal';
import * as React from 'react';

export type InferArguments<Func> = Func extends (arg: infer Argument) => unknown ? Argument : never;
type CRUDHook<Args extends object, Value extends object> = (args: Args) => Value;
type InferHookArguments<Hook> = Hook extends CRUDHook<infer Arguments, object> ? Arguments : never;

// Add default parameters to CRUD hooks, such as `skip`, to allow skipping queries
export interface CRUDHookDefaultParams {
  skip?: boolean;
}

export type CRUDHookParams<Variables extends object> = Variables & CRUDHookDefaultParams;

export const uninitializedHandler = async () => {
  console.warn('uninitialized handler called —— this means a context has been called before initialization');
  return undefined;
};

export const uninitializedAsyncCallback = [async () => null, false] as [() => Promise<null>, false];

// Streamline mutation results
export interface IMutationSuccessResponse<T> {
  note: T;
}

export interface IMutationFailureResponse {
  fieldErrors?: FieldError[];
  errors?: string[];
  error?: string;
}

export type TMutationResponse<MutationSuccessResponse extends { [key: string]: object }> =
  | MutationSuccessResponse
  | IMutationFailureResponse;

/**
 * Create a context for the CRUD operations
 * to allow using it further down the tree without
 * remounting the same hooks
 *
 * Usage:
 * createCRUDContext<typeof useHook>({ onUpdate: noop, onDelete: noop })
 */
export default function createCRUDContext<
  Args extends object,
  Value extends object,
  Hook extends CRUDHook<Args, Value>
>(useHook: Hook, INITIAL_STATE: ReturnType<Hook>) {
  const Context = React.createContext<ReturnType<Hook>>(INITIAL_STATE);

  // Set the name of the Provider to the provider-version
  // of the hook, so it doesn't just show up as `Provider`
  // when debugging the React tree
  const providerName = `${useHook.name.replace(/^use/, '')}Provider`;
  function Provider(props: React.PropsWithChildren<InferHookArguments<Hook>>) {
    const { children, ...rest } = props;
    const value = useHook(rest as InferHookArguments<Hook>) as ReturnType<Hook>;
    const old = React.useRef<typeof value>(value);

    // Leaving these in for debugging if needed later,
    // just flip DEBUG_PROVIDERS to true
    useEffect(() => {
      if (config.apollo.hooks.providers.debug) {
        debug(`[Apollo::Hooks::Crud::${providerName}]: Mounted`);
        return () => {
          debug(`[Apollo::Hooks::Crud::${providerName}]: Unmounted`);
        };
      }
      return undefined;
    }, []);

    // Print debugging information
    useEffect(() => {
      if (config.apollo.hooks.providers.debug) {
        try {
          // if (stringify(value) !== stringify(old.current)) {
          //   const changedPropKeys = difference(Object.entries(old.current), Object.entries(value)).map(([key]) => key);
          //   debug(`[Apollo::Hooks::Crud::${providerName}]: props changed: ${changedPropKeys.join(',')}`, {
          //     previous: old.current,
          //     props: value
          //   });
          // }
        } finally {
          old.current = value;
        }
      }
    }, [value]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  }
  Provider.displayName = providerName;

  function withProvider<
    ComponentProps extends object,
    ProviderProps extends InferHookArguments<Hook>,
    WrappedComponentProps extends ComponentProps & ProviderProps
  >(Component: React.ComponentType<ComponentProps>): React.ComponentType<WrappedComponentProps> {
    return function Wrapped(props: WrappedComponentProps) {
      return (
        <Provider {...props}>
          <Component {...props} />
        </Provider>
      );
    };
  }

  function useContext() {
    return React.useContext(Context);
  }

  return {
    Provider: React.memo(Provider, fastDeepEqual),
    useContext,
    Context,
    INITIAL_STATE,
    withProvider
  };
}
