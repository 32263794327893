/* eslint-disable global-require */
import { createStyles } from '@zavy360/config/themes';
import { useCallback, useEffect, useMemo } from '@zavy360/hooks/react';
import Lottie, { type LottieOptions, type LottieRef } from 'lottie-react';
import * as React from 'react';
import { clsx } from 'clsx';
import { type TSize, getSize } from '../utils/getSize';

export enum LottieAnimation {
  Spinner = 'spinner',
  SpinnerAlt = 'spinner-alt',
  Loading = 'loading',
  LoadingAlt = 'loading-alt',
  LoadingChart = 'loading-chart',
  Doctor = 'doctor',
  Medicine = 'medicine',
  Register = 'register',
  ErrorWater = 'error-water',
  ErrorWoman = 'error-woman',
  HandWithPhone = 'hand-with-phone',
  Images = 'images',
  Email = 'email',
  Notes = 'notes',
  InvoicePatients = 'invoice-patients',
  Templates = 'templates',
  OnlineBookings = 'online-bookings'
}

interface IAnimationProps {
  json: LottieAnimation;
  className?: string;
  size?: TSize;
  variant?: 'loop' | 'intermittent' | 'once';
  onComplete?(): void;
  onStart?(): void;
  onCompleteLoop?(): void;
}

// Generate a class for each size in the default size map

const useStyles = createStyles({
  container: ({ size = 'small' }: Pick<IAnimationProps, 'size'>) => ({
    height: getSize(size)
  })
});

export function Animation(props: IAnimationProps) {
  const { json, size, variant = 'loop', className, onCompleteLoop, onStart } = props;

  const ref: LottieRef = React.useRef(null);
  const animationTimer = React.useRef<ReturnType<typeof setInterval>>(null);

  const animationData = useMemo(() => {
    switch (json) {
      case LottieAnimation.Spinner:
        return require('./lottie/spinner.json');
      case LottieAnimation.SpinnerAlt:
        return require('./lottie/spinner-2.json');
      case LottieAnimation.Loading:
        return require('./lottie/loading.json');
      case LottieAnimation.LoadingAlt:
        return require('./lottie/loading2.json');
      case LottieAnimation.LoadingChart:
        return require('./lottie/chart-loading.json');
      case LottieAnimation.Doctor:
        return require('./lottie/107925-doctor.json');
      case LottieAnimation.Medicine:
        return require('./lottie/94896-medicine.json');
      case LottieAnimation.Register:
        return require('./lottie/42476-register.json');
      case LottieAnimation.ErrorWater:
        return require('./lottie/error-water.json');
      case LottieAnimation.ErrorWoman:
        return require('./lottie/error-woman.json');
      case LottieAnimation.Images:
        return require('./lottie/images.json');
      case LottieAnimation.Email:
        return require('./lottie/email.json');
      case LottieAnimation.Notes:
        return require('./lottie/notes.json');
      case LottieAnimation.InvoicePatients:
        return require('./lottie/invoice-patients.json');
      case LottieAnimation.Templates:
        return require('./lottie/templates.json');
      case LottieAnimation.OnlineBookings:
        return require('./lottie/online-bookings.json');
      case LottieAnimation.HandWithPhone:
        return require('./lottie/hand-with-phone-scroll.json');
      default:
        return json;
    }
  }, [json]);

  const options: LottieOptions = useMemo(
    () => ({
      animationData,
      autoplay: true,
      loop: variant === 'loop'
    }),
    [animationData, variant]
  );

  const onAnimationStart = useCallback(() => {
    onStart?.();
  }, [onStart]);

  const onAnimationLoopEnd = useCallback(() => {
    onCompleteLoop?.();
  }, [onCompleteLoop]);

  const classes = useStyles({ size });

  useEffect(() => {
    if (variant !== 'intermittent') return;
    if (!ref.current) return;
    if (animationTimer.current) clearInterval(animationTimer.current);
    animationTimer.current = setInterval(() => {
      // Go to the first frame, and play once
      ref.current?.goToAndPlay(0);
    }, 2000);
  }, [variant]);

  return (
    <Lottie
      className={clsx(classes.container, className)}
      {...options}
      onSegmentStart={onAnimationStart}
      onLoopComplete={onAnimationLoopEnd}
      animationData={options.animationData}
      lottieRef={ref}
    />
  );
}
