import { useEnvironmentQuery, useHealthStatusQuery, useHealthSubscription } from '@zavy360/graphql/hooks';
import { useEffect, useMemo } from '@zavy360/hooks/react';
import createCRUDContext, { uninitializedHandler } from './factory';
import packageJson from '../../../../../package.json';
import { useApolloLinkContext } from '../client/links/context';
import { ApplicationEnvironments } from '../schema';

const { version } = packageJson;

export function useApplicationEnvironment() {
  const query = useEnvironmentQuery();

  const { setCtx } = useApolloLinkContext();

  const { data } = query;

  // Update global Apollo Context when we receive environment variables
  // from the backend. This lets us disable or enable request batching
  // from the backend.
  useEffect(() => {
    if (!data?.environment?.configuration) return;

    setCtx((prev) => {
      return {
        ...prev,
        ...(data?.environment?.configuration || {})
      };
    });
  }, [data?.environment?.configuration, setCtx]);

  const { data: health, updateQuery: updateHealthQuery } = useHealthStatusQuery();
  const { error } = useHealthSubscription({
    onData({ data: subscriptionData }) {
      if (!subscriptionData?.data?.health?.status) return;
      query?.updateQuery((prev) => {
        return {
          ...prev,
          environment: {
            ...prev?.environment,
            bundleVersion: subscriptionData?.data?.health?.status?.bundleVersion,
            commit: subscriptionData?.data?.health?.status?.commit,
            releaseTime: subscriptionData?.data?.health?.status?.buildTime,
            version: subscriptionData?.data?.health?.status?.version
          }
        };
      });
      updateHealthQuery((prev) => ({
        ...prev,
        health: {
          ...prev?.health,
          ...subscriptionData?.data?.health?.status
        }
      }));
    }
  });

  const value = useMemo(
    () => ({
      outdated:
        query?.data?.environment?.environment !== ApplicationEnvironments.Local &&
        query?.data?.environment?.bundleVersion &&
        version !== query?.data?.environment?.bundleVersion,
      loading: query?.loading && !query?.data?.environment,
      health: health?.health,
      env: data?.environment,
      refetch: query.refetch
    }),
    [data?.environment, health?.health, query?.data?.environment, query?.loading, query.refetch]
  );

  return value;
}

const { Context, Provider, useContext } = createCRUDContext(useApplicationEnvironment, {
  loading: false,
  env: null,
  health: null,
  outdated: false,
  refetch: uninitializedHandler
});

export { Provider as ApplicationEnvironmentProvider, Context, useContext as useEnvironment };
