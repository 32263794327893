import type { DefaultContext } from '@apollo/client';
import { createContext } from '@zavy360/hooks/createContext';
import { noop } from 'lodash';
import { useState } from 'react';
import { setContext } from '@apollo/client/link/context';

export interface ApolloContext extends DefaultContext {
  disableRequestBatching?: boolean;
}

/**
 * Keep track of a global context object that can be accessed
 * in Apollo queries. This is merged into the default Apollo Context,
 * which allows us to access these values in queries and Apollo links.
 */
function useGlobalApolloContext() {
  const [ctx, setCtx] = useState<ApolloContext>({
    disableRequestBatching: false
  });

  return { ctx, setCtx } as const;
}

const { Provider: ApolloContextProvider, useContext: useApolloLinkContext } = createContext(useGlobalApolloContext, {
  ctx: {},
  setCtx: noop
});

export function useContextLink() {
  const { ctx } = useApolloLinkContext();

  return setContext(async (_, context) => {
    return {
      ...context,
      ...ctx
    };
  });
}

export { ApolloContextProvider, useApolloLinkContext };
