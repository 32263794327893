import { useMemo } from '@zavy360/hooks/react';

type InferredNode<T> = T extends { edges?: { node?: infer U }[] } ? U : never;

export function getNodes<T extends { edges?: { node?: object }[] }, NodeType extends InferredNode<T>>(
  results?: T
): NodeType[] {
  return results?.edges?.map((edge) => edge?.node as NodeType)?.filter(Boolean) || ([] as NodeType[]);
}

// Extract nodes from edges, since we do this pretty much
// all the time
export function useNodes<T extends { edges?: { node?: object }[] }, NodeType extends InferredNode<T>>(
  results?: T
): NodeType[] {
  return useMemo(() => {
    return getNodes(results);
  }, [results]) as NodeType[];
}
